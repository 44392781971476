import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericBaseComponent } from './components/generic-base/generic-base.component';

@NgModule({
  declarations: [GenericBaseComponent],
  exports: [GenericBaseComponent],
  imports: [CommonModule]
})
export class AbstractBaseModule {}
