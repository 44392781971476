import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationService } from '@services/navigation.service';
import { NextHeaderTestIds } from '@layouts/next-header/next-header.enum';
import { IconColor, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';

@Component({
  selector: 'next-header',
  templateUrl: './next-header.component.html',
  styleUrl: './next-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextHeaderComponent {
  @Input() isBootSuccess: boolean;

  protected readonly NextHeaderTestIds = NextHeaderTestIds;
  protected readonly NextIcon = NextIcon;

  constructor(private _navigationService: NavigationService) {}

  openMenu(isOpen: boolean) {
    this._navigationService.setOpenMenuStatus(isOpen);
  }

  goToTaskBoard() {
    this._navigationService.navigateToRouteConfig(TaskBoardRouteConfig.mainRoute).then();
  }

  protected readonly IconColor = IconColor;
}
