<div class="header">
  <div class="header__menu-container" (click)="openMenu(true)">
    <link-button
      [iconType]="NextIcon.MENU"
      [iconColor]="IconColor.BRAND_PRIMARY"
      [isDisabled]="!isBootSuccess"
      [testIdDataAttr]="NextHeaderTestIds.MENU"
    ></link-button>
    <span>{{ 'MENU.TITLE' | translate }}</span>
  </div>
  <div class="logo">
    <img src="assets/images/logo.png" alt="Ford Credit" />
  </div>
  <div class="options">
    <link-button [iconType]="NextIcon.LIST" pBadge severity="danger" (clickEvt)="goToTaskBoard()"></link-button>
    <i class="pi pi-bell"></i>
    <i class="pi pi-search"></i>
    <i class="pi pi-user"></i>
  </div>
</div>
