import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { APP_ROUTES } from './app.routes';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './core/modules/auth/auth.module';
import { NextMenuModule } from '@components/organisms/next-menu/next-menu.module';
import { translateConfig } from './core/config/translate.config';
import { translateProviders } from './core/config/initializer.config';
import { AppInterceptorService } from './core/interceptors/app-interceptor.service';
import { NextBreadcrumbModule } from '@components/molecules/next-breadcrumb/next-breadcrumb.module';
import { ConfirmDialogModule } from '@components/organisms/confirm-dialog/confirm-dialog.module';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(APP_ROUTES, { enableTracing: false, bindToComponentInputs: true }),
    SharedModule,
    NextMenuModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    HttpClientModule,
    TranslateModule.forRoot(translateConfig),
    ToastModule,
    NextBreadcrumbModule,
    ConfirmDialogModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    ...translateProviders,
    MessageService
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
