import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { filter, switchMap } from 'rxjs';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private _authService: AuthService,
    private _meService: MeService
  ) {}

  startApp() {
    return this._authService.getAuthenticationObservable().pipe(
      filter(isLogged => !!isLogged),
      switchMap(() => this._meService.getAndSetMeSession())
    );
  }
}
