import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { OAuthCallbackParams } from '../../../../shared/enums/auth.enum';

@Component({
  selector: 'oauth-callback',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthCallbackComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const fragment = NextValueHelper.defaultValue(this._activatedRoute.snapshot.fragment, '');
    const parametersObj = this._authService.mapUrlParams(fragment);
    const idToken = parametersObj[OAuthCallbackParams.ID_TOKEN];
    const accessToken = parametersObj[OAuthCallbackParams.ACCESS_TOKEN];
    const isTokenCallbackValid = this._authService.isTokenCallbackValid(idToken);
    let isLoginSuccessful = false;

    if (isTokenCallbackValid) {
      this._authService.setBrowserSession(idToken, accessToken);
      isLoginSuccessful = true;
    } else {
      console.error('TokenInvalid');
      // TODO this._authService.emitAuthLogEvent(isLoginSuccessful);
    }
    this._authService.emitAuthLogEvent(isLoginSuccessful);
  }
}
