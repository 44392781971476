import { TranslateService } from '@ngx-translate/core';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { AppSettingsConstant } from '@constants/app-settings.constant';

function appInitializerFactory(translateService: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        translateService.addLangs(AppSettingsConstant.availableLanguages);
        translateService.setDefaultLang(AppSettingsConstant.defaultLanguage);
        translateService.use(AppSettingsConstant.defaultLanguage).subscribe({
          next: () => {
            resolve(null);
          },
          error: () => {
            // Redirect to error
            resolve(null);
          }
        });
      });
    });
}

export const translateProviders = [
  { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true }
];
