import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { Observable } from 'rxjs';
import { ConfirmDialogType } from '@components/organisms/confirm-dialog/confirm-dialog.type';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {
  defaultTitleKey = 'CONFIRM.TITLE';
  defaultSummaryKey = 'CONFIRM.SUMMARY';

  config$: Observable<ConfirmDialogType | undefined>;
  protected readonly ConfirmDialogProps = ConfirmDialogProps;

  constructor(private _confirmService: ConfirmServiceService) {}

  ngOnInit(): void {
    this.config$ = this._confirmService.getConfirmObservable();
  }

  cancel(cancelCallback: any) {
    this._confirmService.hide();
    if (cancelCallback) {
      cancelCallback();
    }
  }

  confirm(confirmCallbak: any) {
    this._confirmService.hide();
    if (confirmCallbak) {
      confirmCallbak();
    }
  }
}
