import { NextRouteProps } from '../../core/enums/route.enum';
import { authGuardFn } from '../../shared/guards/auth.guard';
import { NewBusinessRouteConfig } from '@pages/new-business/new-business.route.config';
import { VehicleOrderRouting } from '@pages/new-business/pages/vehicle-order/vehicle-order.routing';

export class NewBusinessRouting {
  static entry = {
    path: NewBusinessRouteConfig.mainRoute.path,
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: NewBusinessRouteConfig.mainRoute
    },
    children: [VehicleOrderRouting.entry],
    canActivate: [authGuardFn]
  };
}
