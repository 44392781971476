<div class="app__container">
  <next-header [isBootSuccess]="isBootSuccessful"></next-header>
  <next-breadcrumb></next-breadcrumb>
  <main class="app__main-container">
    <router-outlet></router-outlet>
    <p-toast></p-toast>
  </main>
  <next-footer></next-footer>
</div>
<next-menu *ngIf="isBootSuccessful"></next-menu>
<confirm-dialog></confirm-dialog>
