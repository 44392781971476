import { UserRouteConfig } from '@pages/general-maintenance/pages/user/user.route.config';
import { NavSection } from '../enums/navigation.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { GenericObject } from '@utils/models/Types';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { CurrencyRouteConfig } from '@pages/general-maintenance/pages/currency/currency.route.config';
import { CountryRouteConfig } from '@pages/general-maintenance/pages/country/contry.route.config';
import { BankSecurityRouteConfig } from '@pages/general-maintenance/pages/bank-security/bank-security.route.config';
import { SourceLocationRouteConfig } from '@pages/general-maintenance/pages/source-location/source-location.route.config';
import { VehicleOrderRouteConfig } from '@pages/new-business/pages/vehicle-order/vehicle-order.route.config';

export class NavigationConstant {
  static sectionTree: GenericObject = {
    [NavSection.GENERAL_MAINTENANCE]: {
      id: NavSection.GENERAL_MAINTENANCE,
      label: 'MENU.GENERAL_MAINTENANCE',
      icon: NextIcon.MAINTENANCE,
      items: [
        UserRouteConfig.listConfig,
        CurrencyRouteConfig.listConfig,
        CountryRouteConfig.listConfig,
        SourceLocationRouteConfig.listConfig,
        BankSecurityRouteConfig.listConfig
      ]
    },
    [NavSection.DISTRIBUTOR_MANAGER]: {
      id: NavSection.DISTRIBUTOR_MANAGER,
      icon: NextIcon.DISTRIBUTOR,
      label: 'MENU.DISTRIBUTOR_MODULE',
      items: [DistributorManagerRouteConfig.mainRoute]
    },
    [NavSection.NEW_BUSINESS]: {
      id: NavSection.NEW_BUSINESS,
      icon: NextIcon.NEW_BUSINESS,
      label: 'MENU.NEW_BUSINESS_MODULE',
      items: [VehicleOrderRouteConfig.mainRoute]
    }
  };

  static menu = [
    NavigationConstant.sectionTree[NavSection.GENERAL_MAINTENANCE],
    NavigationConstant.sectionTree[NavSection.DISTRIBUTOR_MANAGER],
    NavigationConstant.sectionTree[NavSection.NEW_BUSINESS]
  ];
}
