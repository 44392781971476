import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastMsgService } from '@services/toast-msg.service';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BackendErrorService {
  constructor(
    private toastService: ToastMsgService,
    private _authService: AuthService
  ) {}

  handleError(error: HttpErrorResponse, skipMessage = false): Observable<any> {
    if (!skipMessage) {
      this.toastService.error(error.message);
    }

    this._handleUnauthorized(error);
    return throwError(() => new Error(error.message));
  }

  _handleUnauthorized(error: HttpErrorResponse) {
    if (error.status === 401) {
      this._authService.authenticationRedirection();
    }
  }
}
