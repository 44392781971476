import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { NextMenuComponent } from './next-menu.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NextMenuComponent],
  imports: [CommonModule, TranslateModule, SidebarModule, PanelMenuModule],
  exports: [NextMenuComponent],
  providers: [DatePipe]
})
export class NextMenuModule {}
