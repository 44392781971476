import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextFooterComponent } from '@layouts/next-footer/next-footer.component';
import { NextHeaderComponent } from '@layouts/next-header/next-header.component';
import { AbstractBaseModule } from '@components/atoms/abstract-base/abstract-base.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TestIdDataAttrDirective } from '../core/directives/test-id-data-attr.directive';
import { VisualModule } from '@components/atoms/visual/visual.module';
import { BadgeModule } from 'primeng/badge';
import { ButtonsModule } from '@components/atoms/buttons/buttons.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {ButtonModule} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    AbstractBaseModule,
    TranslateModule,
    TestIdDataAttrDirective,
    VisualModule,
    BadgeModule,
    ButtonsModule,
    ButtonModule
  ],
  declarations: [NextFooterComponent, NextHeaderComponent, PageNotFoundComponent],
  exports: [NextFooterComponent, NextHeaderComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return { ngModule: SharedModule };
  }
}
