import { Routes } from '@angular/router';
import { authGuardFn } from './shared/guards/auth.guard';
import { NextRouteProps } from './core/enums/route.enum';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { TaskBoardRouting } from '@pages/task-board/task-board.routing';
import { GeneralMaintenanceRoutingConfig } from '@pages/general-maintenance/general-maintenance.route.config';
import { NewBusinessRouting } from '@pages/new-business/new-business.routing';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuardFn]
  },
  {
    path: DistributorManagerRouteConfig.mainRoute.path,
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.mainRoute
    },
    loadChildren: () => import('@pages/distributor/distributor-manager.module').then(m => m.DistributorManagerModule),
    canActivate: [authGuardFn]
  },
  {
    path: GeneralMaintenanceRoutingConfig.mainRoute.path,
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: GeneralMaintenanceRoutingConfig.mainRoute
    },
    loadChildren: () => import('@pages/general-maintenance/general-maintenance.module').then(m => m.GeneralMaintenanceModule),
    canActivate: [authGuardFn]
  },
  TaskBoardRouting.entry,
  NewBusinessRouting.entry,
  {
    path: '404',
    canActivate: [authGuardFn],
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];
