import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationService } from '@services/navigation.service';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { MenuItem } from 'primeng/api';
import { NavigationConstant } from '@constants/navigation.constant';

@Component({
  selector: 'next-menu',
  templateUrl: './next-menu.component.html',
  styleUrl: './next-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextMenuComponent extends GenericBaseComponent implements OnInit {
  isOpen = false;
  menuOptions: MenuItem[];

  constructor(
    private _navigationService: NavigationService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.menuOptions = this._navigationService.buildMenu(NavigationConstant.menu);
    this.subs.push(
      this._navigationService.getMainMenuOpenStatusObs().subscribe(isOpen => {
        this.isOpen = isOpen;
        this._cdr.markForCheck();
      })
    );
  }

  visibleStatusChange(status: boolean) {
    this._navigationService.setOpenMenuStatus(status);
  }

  onMenuItemClick() {
    this._navigationService.setOpenMenuStatus();
  }
}
