<next-dialog *ngIf="config$ | async as config" [visible]="true"
             [titleKey]="(config.titleKey || defaultTitleKey)"
             [submitLabelKey]="config.confirmLabelKey!"
             [cancelLabelKey]="config.cancelLabelKey!"
             [hideDismiss]="config[ConfirmDialogProps.HIDE_DISMISS]!"
             [dialogSize]="config[ConfirmDialogProps.SIZE]!"
             (cancelClick)="cancel(config[ConfirmDialogProps.CANCEL_CALLBACK])"
             (submitClick)="confirm(config[ConfirmDialogProps.CONFIRM_CALLBACK])">
  <div dialogContent>{{(config.summaryKey || defaultSummaryKey) | translate}}</div>
</next-dialog>
