import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OAuthCallbackComponent } from '../components/oauth-callback/oauth-callback.component';
import { AuthRouteConfig } from './auth.route.config';

export class AuthRouting {
  static readonly routes: Routes = [
    {
      path: AuthRouteConfig.oauth.path,
      component: OAuthCallbackComponent,
      data: {
        routeConfig: AuthRouteConfig.oauth
      }
    }
  ];
}

@NgModule({
  imports: [RouterModule.forChild(AuthRouting.routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
