import { ChangeDetectionStrategy, Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent {

  constructor(private router: Router) {
  }

  getDashboard() {
    this.router.navigateByUrl('/');
  }

}
