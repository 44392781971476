import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { NavigationService } from '@services/navigation.service';
import { Router } from '@angular/router';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { AppService } from './core/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends GenericBaseComponent implements OnInit, OnDestroy {
  private translate: TranslateService = inject(TranslateService);
  private primengConfig: PrimeNGConfig = inject(PrimeNGConfig);
  private _appService = inject(AppService);
  private _navigationService = inject(NavigationService);
  private _router = inject(Router);

  isBootSuccessful = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this._setLanguageConfig();
    this._startUserSession();
  }

  private _setLanguageConfig() {
    this.translate.setDefaultLang('en');
    this.subs.push(
      this.translate.stream('primeng').subscribe(data => {
        this.primengConfig.setTranslation(data);
      })
    );
  }

  private _startUserSession() {
    const bootSub = this._appService.startApp().subscribe({
      next: () => this._handleBootSuccess(),
      error: () => this._handleBootFailed()
    });

    this.subs.push(bootSub);
  }

  private _handleBootSuccess() {
    this.isBootSuccessful = true;
    this._handleRedirection();
  }

  private _handleBootFailed() {
    console.error('Boot Failed');
  }

  private _handleRedirection(): void {
    const redirectUrl = NextValueHelper.defaultValue(this._navigationService.getStoredUrl());

    if (redirectUrl) {
      this._router
        .navigateByUrl(decodeURIComponent(redirectUrl))
        .then(() => this._navigationService.removeRedirectUrl());
    }
  }
}
