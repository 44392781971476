import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { BackendConstant } from '@constants/backend.contant';
import { BackendErrorService } from '@services/backend-error.service';

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _backendErrorService: BackendErrorService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._handleRequest(req, next);
  }

  private _handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      Authorization: `Bearer ${this._authService.getAccessToken()}`
    };

    const request = req.clone({
      setHeaders: headers
    });

    return next.handle(request).pipe(
      catchError(err => {
        const skipErrorMsg = request.headers.get(BackendConstant.skipErrorMsgHeaderParam) === 'true';

        return this._backendErrorService.handleError(err, skipErrorMsg);
      })
    );
  }
}
