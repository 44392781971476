import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth.routing.module';
import { OAuthCallbackComponent } from '../components/oauth-callback/oauth-callback.component';

@NgModule({
  declarations: [OAuthCallbackComponent],
  imports: [RouterModule, AuthRoutingModule]
})
export class AuthModule {}
