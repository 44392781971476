import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonRoutingService } from 'app/core/services/common-routing.service';

@Component({
  selector: 'next-footer',
  templateUrl: './next-footer.component.html',
  styleUrl: './next-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextFooterComponent {
  constructor(
    private _commonRoutingService: CommonRoutingService
  ) {}

  openDisclaimer() {
    this._commonRoutingService.navigateByUrlWrapper('/assets/docs/disclaimer.pdf', true)
  }
}
