import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from '@components/organisms/confirm-dialog/confirm-dialog.component';
import { NextDialogModule } from '@components/organisms/next-dialog/next-dialog.module';
import { TranslateModule } from '@ngx-translate/core';

const exported = [ConfirmDialogComponent];

@NgModule({
  declarations: [...exported],
  exports: [...exported],
  imports: [CommonModule, NextDialogModule, TranslateModule]
})
export class ConfirmDialogModule {}
