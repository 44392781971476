<div class="footer">
  <div class="content">
    <div class="column">
      <a class="link">{{ 'FOOTER.COPYRIGHT_COMPANY' | translate}}</a>
      <a class="link action" (click)="openDisclaimer()">{{ 'FOOTER.DISCLAIMER' | translate}}</a>
    </div>
  </div>
  <div class="content content__right">
    <div class="column">
      <img src="assets/images/ford.svg" class="logo" alt="Ford" />
    </div>
  </div>
</div>